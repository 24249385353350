import {
  Award,
  CalendarDays,
  CircuitBoard,
  Codesandbox,
  FileText,
  Handshake,
  LifeBuoy,
  LocateFixed,
  Mail,
  Map,
  MessageSquare,
  MonitorDot,
  PackagePlus,
  PictureInPicture2,
  PieChart,
  RadioTower,
  ScrollText,
  Share2,
  ShoppingBag,
  Table,
  Building,
  Trophy,
  UserCog,
  UserCog2,
  UserRound,
} from "lucide-react";

const menuData: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "adminlist",
    label: "Admins",
    link: "/",
    icon: <LocateFixed />,
  },
  {
    id: "leads",
    label: "Leads",
    link: "/leads",
    icon: <UserRound />,
  },
  {
    id: "media",
    label: "Media",
    link: "/media",
    icon: <UserRound />,
  },
  {
    id: "blog",
    label: "Blog",
    link: "/blog",
    icon: <MonitorDot />,
  },
  {
    id: "blogAuthor",
    label: "BlogAuthor",
    link: "/blogauthor",
    icon: <MonitorDot />,
  },

  {
    id: "Pages",
    label: "Pages",
    link: "/page",
    icon: <MonitorDot />,
  },
  {
    id: "jobs",
    label: "Jobs",
    link: "/jobs",
    icon: <MonitorDot />,
  },
];

export { menuData };
