import React from "react";
import { Facebook, Github, Mail, Twitter } from "lucide-react";

// Formik validation
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik as useFormic } from "formik";
import callApi from "Common/utils/apiCaller";
// Image
import { toast } from "react-toastify";
import logoLight from "assets/images/logo-light.png";
import logoDark from "assets/images/logo-dark.png";
import { loginUser } from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "Common/withRouter";
import { createSelector } from "reselect";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthIcon from "pages/AuthenticationInner/AuthIcon";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const ResetPassword = (props: any) => {
  document.title = "ResetPassword | Antcreatives";
  let [searchParams] = useSearchParams();
  let navigate = useNavigate();
  //   const dispatch = useDispatch<any>();
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  //   const selectLogin = createSelector(
  //     (state: any) => state.Login,
  //     (login) => ({
  //       success: login.success,
  //       error: login.error,
  //     })
  //   );
  useEffect(() => {
    let q_token = searchParams.get("reset_token");
    let q_email = searchParams.get("email");
    setEmail(q_email);
    setToken(q_token);
  }, [searchParams]);
  //   const { success, error } = useSelector(selectLogin);

  const validation: any = useFormic({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
      confirmPassword: Yup.string().required("Please Enter Confirm Password"),
    }),
    onSubmit: async (values: any) => {
      if (values?.password === values?.confirmPassword) {
        try {
          let result = await callApi(
            `v1/admin/auth/reset-password/${token}`,
            "post",
            {
              admin: {
                contact_email: email,
                password: values?.password,
              },
            }
          );
          if (result?.data?.data?.isReset) {
            toast("Password has been reset Successfully");
            navigate("/login");
          } else {
            toast("something went wrong");
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        toast("Please enter correct password");
      }
    },
  });

  React.useEffect(() => {
    const bodyElement = document.body;

    bodyElement.classList.add(
      "flex",
      "items-center",
      "justify-center",
      "min-h-screen",
      "py-16",
      "lg:py-10",
      "bg-slate-50",
      "dark:bg-zink-800",
      "dark:text-zink-100",
      "font-public"
    );

    return () => {
      bodyElement.classList.remove(
        "flex",
        "items-center",
        "justify-center",
        "min-h-screen",
        "py-16",
        "lg:py-10",
        "bg-slate-50",
        "dark:bg-zink-800",
        "dark:text-zink-100",
        "font-public"
      );
    };
  }, []);

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <div className="relative">
        <AuthIcon />

        <div className="mb-0 w-screen lg:mx-auto lg:w-[500px] card shadow-lg border-none shadow-slate-100 relative">
          <div className="!px-10 !py-12 card-body">
            <div className="mt-8 text-center">
              <h4 className="mb-1 text-custom-500 dark:text-custom-500">
                Reset Password
              </h4>
              {/* <p className="text-slate-500 dark:text-zink-200">
                Sign in to continue to AthHub.
              </p>  */}
            </div>

            <form
              className="mt-10"
              id="signInForm"
              onSubmit={(event: any) => {
                event.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="mb-3">
                <label
                  htmlFor="password"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                />
                {validation.touched.password && validation.errors.password ? (
                  <div
                    id="password-error"
                    className="mt-1 text-sm text-red-500"
                  >
                    {validation.errors.password}
                  </div>
                ) : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="passwordConfirmInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Confirm Password
                </label>
                <input
                  name="confirmPassword"
                  type="password"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  required
                  id="passwordConfirmInput"
                  placeholder="Enter Confirm Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.confirmPassword || ""}
                />
                {validation.touched.confirmPassword &&
                validation.errors.confirmPassword ? (
                  <div id="email-error" className="mt-1 text-sm text-red-500">
                    {validation.errors.confirmPassword}
                  </div>
                ) : null}
              </div>

              <div className="mt-10">
                <button
                  type="submit"
                  className="w-full text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                >
                  Submit
                </button>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",

                    padding: "10px 0",
                  }}
                ></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
