import axios from "axios";

// const API_URL = `http://localhost:8080`;
//process.env.REACT_APP_CORE_API ||
const CORE_API = 'https://api.antcreatives.com';
// `https://apidev.athhub.com`;
// ;

export default async function callApi(
    endpoint: string,
    method: "post" | "get" = "post",
    body?: any
  ) {
    let authUser = window.localStorage.getItem("authUser")
      ? window.localStorage.getItem("authUser")
      : null;
  
    let headers = {
      "content-type": "application/json",
    };
  
    if (authUser) {
      let user = JSON.parse(authUser);
      let token = user.token;
      if (token && token !== "") {
        // @ts-ignore
        headers.Authorization = `Bearer ${token}`;
      }
    }
  
    return (await axios({
      url: `${CORE_API}/${endpoint}`,
      method,
      data: body,
      headers,
    })) as any;
  }