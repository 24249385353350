import { PaginationState } from "@tanstack/react-table";
import BreadCrumb from "Common/BreadCrumb";
import TableContainer from "Common/TableContainer";
import callApi from "Common/utils/apiCaller";
import {
  Download,
  Plus,
  Search,
  SlidersHorizontal,
  X,
  Rows4,
  GridIcon,
} from "lucide-react";
import moment from "moment";
import PaginationContainer from "./PaginationContainer";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Pencil, Trash2 } from "lucide-react";
import Drawer from "Common/Components/Drawer";
import _ from "lodash";
import DeleteModal from "Common/DeleteModal";
import Modal from "Common/Components/Modal";
import { TailSpin } from "react-loader-spinner";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import { UploadCloud } from "lucide-react";
import Papa from "papaparse";
import axios from "axios";

const url = `https://api.antcreatives.com`;
const MediaList = () => {
  const [mediaData, setMediaData] = useState([]);
  const [loader, setLoader] = useState<any>();
  const [selectedFile, setSelectedFile] = useState<any>();
  const [search, setSearch] = useState<any>();
  const [showTable, setShowTable] = useState<any>(true);
  const [mediaType, setMediaType] = useState<any>();
  const [medias, setMedias] = useState<any>();
  const [imageData, setImageData] = useState<any[]>();
  const [imageLink, setImageLink] = useState<any>();
  const [fileContent, setFileContent] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [imageCount, setImageCount] = useState(0);
  const [eventData, setEventData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState(0);
  const [render, setRender] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>();
  const [imageItems, setImageItems] = useState<any>();
  const [perPageData] = useState<any>(30);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 30,
  });
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Pagination

  const [page, setPage] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 30,
  });

  //fetch media
  const debouncedHandler = useCallback(
    _.debounce(
      (
        mediaType: any,

        search: any,
        pagination: any,
        render: any
      ) => {
        return fetchDateWithFilters(
          mediaType,

          search,
          pagination,
          render
        );
      },
      700
    ),
    []
  );
  async function fetchDateWithFilters(
    mediaType: any,

    search: any,
    pagination: any,
    render: any
  ) {
    let filters: { [index: string]: any } = {};
    if (mediaType) {
      filters.media_type = mediaType;
    }

    if (search) {
      filters.qtext = search;
    }
    const localData = await callApi("v1/media/list", "post", {
      pageNum: pagination?.pageIndex + 1,
      pageSize: pagination?.pageSize,
      filters,
    });

    if (localData?.data?.status === "Success") {
      setMediaData(localData?.data?.data?.mediaList);
      setPageCount(
        Math.ceil(localData?.data?.data?.mediaCount / pagination?.pageSize)
      );
      setTotalItems(localData?.data?.data.mediaCount);
    }
  }

  const filterSearchData = (e: any) => {
    const search = e.target.value;
    setSearch(search);
  };

  const options = [
    { value: "Select...", label: "Select Media Type" },
    { value: "video", label: "Video" },
    { value: "image", label: "Image" },
    { value: "audio", label: "Audio" },
  ];

  const handleChange = (selectedOption: any) => {
    if (selectedOption.value === "Select...") {
      setMediaType("");
    } else {
      setMediaType(selectedOption.value);
    }
  };
  const handleMediaChange = (selectedOption: any) => {
    if (selectedOption.value === "Select...") {
      setMedias("");
    } else {
      setMedias(selectedOption.value);
    }
  };

  useEffect(() => {
    debouncedHandler(mediaType, search, pagination, render);
  }, [mediaType, search, pagination, render]);
  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell._id) {
      setEventData(cell);
    }
  };

  // const imageDebouncedHandler = useCallback(
  //   _.debounce(
  //     (
  //       mediaType: any,

  //       search: any,
  //       pagination: any,
  //       render: any
  //     ) => {
  //       return fettchMediaFilters(
  //         mediaType,

  //         search,
  //         pagination,
  //         render
  //       );
  //     },
  //     700
  //   ),
  //   []
  // );
  // async function fettchMediaFilters(
  //   mediaType: any,
  //   search: any,
  //   page: any,
  //   render: any
  // ) {
  //   let filters: { [index: string]: any } = {};
  //   if (mediaType) {
  //     filters.media_type = mediaType;
  //   }

  //   if (search) {
  //     filters.qtext = search;
  //   }
  //   const localData = await callApi("v1/media/list", "post", {
  //     pageNum: page?.pageIndex + 1,
  //     pageSize: page?.pageSize,
  //     filters,
  //   });

  //   if (localData?.data?.status === "Success") {
  //     setImageData(localData?.data?.data?.mediaList);
  //     setImageCount(
  //       Math.ceil(localData?.data?.data?.mediaCount / page?.pageSize)
  //     );
  //     setImageItems(localData?.data?.data.mediaCount);
  //   }
  // }
  // //Fetch Imagelist
  // useEffect(() => {
  //   imageDebouncedHandler(mediaType, search, page, render);
  // }, [mediaType, search, page, render]);

  const handleDelete = async () => {
    if (eventData) {
      //dispatch(onDeleteEmployee(eventData.id));

      const res = await callApi(`v1/media/${eventData?._id}/update`, "post", {
        media: { is_deleted: true },
      });
      if (res && res?.data?.status === "Success") {
        setDeleteModal(false);
        setRender(!render);
        toast("Deleted successfully");
      }
    }
  };

  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  const handleMedia = async (data: any) => {
    if (isEdit) {
      // update user
      try {
        const res = await callApi(`v1/media/${eventData?._id}/update`, "post", {
          media: {
            media_url: imageLink,
            media_type: medias,
            content_type: eventData?.content_type,
            file_name: selectedFile?.name,
          },
        });
        if (res && res?.data?.status === "Success") {
          setRender(!render);
          toast("Updated successfully");
          setImageLink("");
          setMedias("");
        }
      } catch (e) {
        toast("something went wrong");
      }
      // dispatch(onUpdateEmployee(updateData));
    } else {
      try {
        const res = await callApi(`v1/media/new`, "post", {
          media: {
            media_url: imageLink,
            media_type: medias,
            content_type: selectedFile?.type,
            file_name: selectedFile?.name,
          },
        });
        if (res && res?.data?.status === "Success") {
          setRender(!render);
          toast("media created successfully");
          setImageLink("");
          setMedias("");
        }
      } catch (e) {
        toast("something went wrong");
      }
    }
    toggle();
  };

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      media_type: medias || "",
    },
    validationSchema: Yup.object().shape({
      media_type: Yup.string().required("Media File is required."),
    }),
    onSubmit: (values) => {
      handleMedia(values);
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
      setSelectedImage("");
    } else {
      setShow(true);
      setEventData("");
      setSelectedImage("");
      validation.resetForm();
    }
  }, [show, validation]);

  const handleAcceptedFiles = async (file: any) => {
    Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
    });
    setSelectedFile(file);
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results: any) {
        setFileContent(results.data);
      },
    });
  };
  const formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  useEffect(() => {
    async function fetchMedia(selectedFile: any) {
      var bodyFormData = new FormData();
      bodyFormData.append("file", selectedFile);
      setLoader(true);
      const ress = await axios({
        method: "post",
        url: `${url}/api/v1/media/upload`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      setLoader(false);
      if (ress) {
        toast("Media Uploaded");
        setImageLink(ress?.data?.data?.link);
      } else {
        toast("something went wrong");
      }
    }
    if (selectedFile) {
      fetchMedia(selectedFile);
    }
  }, [selectedFile]);

  const mediaListColumns = useMemo(
    () => [
      {
        header: "Media Type",
        accessorKey: "media_type",
        enableColumnFilter: false,
        enableSorting: false,
        // cell: (cell) => (
        //   <>{moment(cell.getValue()).format("DD MMM YY HH:mm")}</>
        // ),
      },
      {
        header: "Media Url",
        // accessorKey: "media_url",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <Link
            to={cell.row.original.media_url}
            className="flex items-center gap-3"
            target="_blank"
          >
            <div className="rounded-full size-6 shrink-0 bg-slate-100">
              <img
                src={cell.row.original.media_url}
                alt=""
                className="h-6 rounded-full"
              />
            </div>
          </Link>
        ),
      },
      {
        header: "Content Type",
        accessorKey: "content_type",
        enableColumnFilter: false,
        enableSorting: false,
      },
      // {
      //   header: "File Name",
      //   accessorKey: "file_name",
      //   enableColumnFilter: false,
      //   enableSorting: false,
      // },
      // {
      //   header: "Ref Name",
      //   accessorKey: "ref_name",
      //   enableColumnFilter: false,
      //   enableSorting: false,
      // },

      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-3">
            {/* <Link
              style={{ display: "flex" }}
              to="#!"
              onClick={() => {
                toggleView();
              }}
            >
              grid view
            </Link> */}
            <Link
              to="#!"
              data-modal-target="addEmployeeModal"
              className="flex items-center justify-center transition-all duration-200 ease-linear rounded-md size-8 edit-item-btn bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              onClick={() => {
                const data = cell.row.original;
                handleUpdateDataClick(data);
              }}
            >
              <Pencil className="size-4" />
            </Link>
            <Link
              to="#!"
              className="flex items-center justify-center transition-all duration-200 ease-linear rounded-md size-8 remove-item-btn bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              onClick={() => {
                const data = cell.row.original;
                onClickDelete(data);
              }}
            >
              <Trash2 className="size-4" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {/* {loader ? (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            zIndex: 6,
            background: "#fff",
            opacity: "1",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="flex justify-center">
            <TailSpin visible={true} height="50" width="50" color="#4fa94d" />
          </div>
        </div>
      ) : null} */}
      <BreadCrumb title="Media List" pageTitle="Media List" />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-12 gap-x-5">
        <div className="col-span-12 card 2xl:col-span-12">
          <div className="card-body">
            <div className="flex items-center">
              <h6 className="text-15 grow">Media List</h6>
              <div className="shrink-0">
                <button
                  type="button"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  onClick={toggle}
                >
                  <Plus className="inline-block size-4" />
                  <span className="align-middle">Add Media</span>
                </button>
              </div>
            </div>
          </div>
          <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
            <form action="#!">
              <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                <div className="relative flex justify-around xl:col-span-3">
                  <span
                    style={{
                      cursor: "pointer",
                      marginRight: "3px",
                    }}
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    onClick={() => {
                      setShowTable(true);
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        whiteSpace: "nowrap",
                        alignItems: "center",
                      }}
                    >
                      <Rows4 style={{ paddingRight: "5px" }} />
                      Table View
                    </span>
                  </span>

                  <span
                    style={{ cursor: "pointer" }}
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    onClick={() => {
                      setShowTable(false);
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        whiteSpace: "nowrap",
                        alignItems: "center",
                      }}
                    >
                      <GridIcon style={{ paddingRight: "5px" }} />
                      Grid View
                    </span>
                  </span>
                </div>

                <div className="relative xl:col-span-2">
                  <input
                    type="text"
                    className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Search for content type"
                    autoComplete="off"
                    onChange={(e) => filterSearchData(e)}
                  />
                  <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                </div>

                <div className="xl:col-span-2">
                  <Select
                    className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    options={options}
                    isSearchable={false}
                    defaultValue={options[0]}
                    onChange={(event: any) => handleChange(event)}
                    id="choices-single-default"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="card-body">
            {showTable ? (
              <TableContainer
                isPagination={true}
                columns={mediaListColumns || []}
                data={mediaData || []}
                pageCount={pageCount}
                pagination={pagination}
                totalItems={totalItems}
                onPaginationChange={(updater: any) => {
                  const nextState = updater(pagination);
                  if (nextState?.pageIndex !== pagination?.pageIndex)
                    setPagination(nextState);
                }}
                divclassName="overflow-x-auto"
                tableclassName="w-full whitespace-nowrap"
                theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500"
                tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
                PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              />
            ) : (
              <>
                <div className="grid grid-cols-2 gap-4 mt-2 mb-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 auto-rows-max">
                  {mediaData?.map((item: any) => (
                    <div
                      className="relative"
                      style={{ cursor: "pointer" }}
                      key={item._id}
                      onClick={() => {
                        setEventData(item);
                        setIsEdit(true);
                        setShow(true);
                      }}
                    >
                      <img
                        src={item?.media_url}
                        alt=""
                        className="object-cover w-full h-40 rounded"
                      />
                    </div>
                  ))}
                </div>
                <PaginationContainer
                  isPagination={true}
                  data={mediaData || []}
                  pageCount={pageCount}
                  pagination={pagination}
                  totalItems={totalItems}
                  onPaginationChange={(updater: any) => {
                    const nextState = updater(pagination);
                    if (nextState?.pageIndex !== pagination?.pageIndex)
                      setPage(nextState);
                  }}
                  PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                />
              </>
            )}
          </div>
        </div>
      </div>
      {/* Employee Modal */}
      <Drawer
        show={show}
        onHide={toggle}
        id="drawerEnd"
        drawer-end="true"
        className="fixed inset-y-0 flex flex-col w-1/2 transition-transform duration-300 ease-in-out transform bg-white shadow ltr:right-0 rtl:left-0 z-drawer dark:bg-zink-600"
      >
        <Drawer.Header
          className="flex items-center justify-between p-4 border-b card-body border-slate-200 dark:border-zink-500"
          closeButtonClass="lucide lucide-x size-4 transition-all duration-200 ease-linear text-slate-500 hover:text-slate-700 dark:text-zink-200 dark:hover:text-zink-50"
        >
          <Drawer.Title className="text-15">
            {!!isEdit ? "Edit Media" : "Add Media"}
          </Drawer.Title>
          <X className="transition-all duration-200 ease-linear size-4 text-slate-500 hover:text-slate-700 dark:text-zink-200 dark:hover:text-zink-50"></X>
        </Drawer.Header>
        <Drawer.Body className="h-full p-4 overflow-y-auto">
          <form
            className="create-form"
            id="create-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <input type="hidden" value="" name="id" id="id" />
            <input type="hidden" value="add" name="action" id="action" />
            <input type="hidden" id="id-field" />
            <div
              id="alert-error-msg"
              className="hidden px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-500/20"
            ></div>
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
              <div className="xl:col-span-12">
                <label
                  htmlFor="phoneNumberInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Media Type
                </label>
                <Select
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  options={options}
                  isSearchable={false}
                  defaultValue={options[0]}
                  onChange={(event: any) => handleMediaChange(event)}
                  id="choices-single-default3"
                />
                {validation.touched.media_type &&
                validation.errors.media_type ? (
                  <p className="text-red-400">{validation.errors.media_type}</p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <h6 className="mb-4 text-15">Upload Media</h6>
                {!selectedFile &&
                  (loader ? (
                    <div className="flex justify-center">
                      <TailSpin
                        visible={true}
                        height="50"
                        width="50"
                        color="#4fa94d"
                      />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center bg-white border border-dashed rounded-md cursor-pointer dropzone border-slate-300 dropzone2 dark:bg-zink-700 dark:border-zink-500">
                      <Dropzone
                        multiple={false}
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles[0]);
                        }}
                      >
                        {({ getRootProps, getInputProps }: any) => (
                          <div
                            className="w-full py-5 text-lg text-center dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <UploadCloud className="block mx-auto size-12 text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500"></UploadCloud>
                            </div>

                            <h5 className="mb-0 font-normal text-slate-500 text-15">
                              Drag and drop your files or{" "}
                              <a href="#!">browse</a> your files
                            </h5>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  ))}
                {selectedFile &&
                  (loader ? (
                    <div
                      className="flex justify-center"
                      style={{ marginTop: "10px" }}
                    >
                      <TailSpin
                        visible={true}
                        height="50"
                        width="50"
                        color="#4fa94d"
                      />
                    </div>
                  ) : (
                    <ul className="mb-0" id="dropzone-preview">
                      <li className="mt-2" id="dropzone-preview-list">
                        <div className="border rounded border-slate-200 dark:border-zink-500">
                          <div className="flex p-2">
                            <div className="shrink-0 me-3">
                              <div className="p-2 rounded-md size-14 bg-slate-100 dark:bg-zink-600">
                                <img
                                  data-dz-thumbnail
                                  className="block w-full h-full rounded-md"
                                  src={selectedFile?.preview}
                                  alt={selectedFile?.name}
                                />
                              </div>
                            </div>
                            <div className="grow">
                              <div className="pt-1">
                                <h5 className="mb-1 text-15" data-dz-name>
                                  {selectedFile?.name}
                                </h5>
                                <p
                                  className="mb-0 text-slate-500 dark:text-zink-200"
                                  data-dz-size
                                >
                                  {selectedFile?.formattedSize}
                                </p>
                              </div>
                            </div>
                            <div className="shrink-0 ms-3">
                              <button
                                data-dz-remove
                                type="button"
                                className="px-2 py-1.5 text-xs text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20"
                                onClick={() => {
                                  setSelectedFile(undefined);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  ))}
              </div>
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                id="close-modal"
                data-modal-close="addEmployeeModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                id="addNew"
                onClick={() => {
                  setSelectedFile("");
                }}
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update " : "Add Media"}
              </button>
            </div>
          </form>
        </Drawer.Body>
      </Drawer>
    </React.Fragment>
  );
};

export default MediaList;
